<template>
  <error-page
    code="500"
    desc="抱歉，服务器出错了"
    :src="src"
  />
</template>

<script>
import error500 from "@/assets/images/error/error-500.svg";
import errorPage from "./module/error-page";
export default {
  components: {
    errorPage,
  },
  data() {
    return {
      src: error500,
    };
  },
};
</script>

<style>
</style>
